.c-post.c-post__learn {
  padding-top: $space * 3;

  .c-post__hero {
    display: block;
  }

  .c-post__lower {
    padding-top: 0;
  }

  .c-post__hero-cat {
    i {
      font-size: 9px;
    }
  }

  hr {
    margin-top: $space * 1.5;
  }
}
