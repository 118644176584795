body {
  padding-top: $header;

  &.home,
  &.category-planning {
    @include bp(medium) {
      padding-top: $header + 26px;
    }
  }
}

body.single-post {
  padding-top: $header;
}
