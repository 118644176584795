.c-scrpr__wrapper {
  position: relative;
  width: 100%;
  height: 4px;
  background-color: $color-very-light-grey;
}

.c-scrpr {
  width: 0;
  height: 100%;
  background-color: $color-purple;

  .post-template-single-wf-branded & {
    background-color: $color-aqua;
  }
}