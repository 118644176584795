.home {
  .c-archive__news-wrapper {
    position: relative;
    order: 2;

    &:before {
      @include bp-down(medium) {
        content: '';
        position: absolute;
        left: $space * -2;
        right: $space * -2;
        top: 0;
        height: 100%;
        display: block;
        background: linear-gradient(180deg, #4840BB 30%, #8F53D7 100%);
        opacity: 0.1;
        z-index: 0;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: $space * 2;
      right: $space * -2;
      display: block;
      width: 190px;
      height: 210px;
      background: url('../assets/images/wealthfront-logo.svg') no-repeat left center;
      background-size: auto 100%;
      opacity: 0.05;
      z-index: 1;

      @include bp-between(medium, large) {
        right: auto;
        left: $space * 2;
        height: 145px;
      }

      @include bp(large) {
        right: $space * 2;
        width: 75%;
        background-size: contain;
        background-position: right center;
      }
    }

    @include bp(large) {
      order: 1;
    }
  }

  .c-archive__news {
    position: relative;
    padding-top: $space * 2.5;
    padding-bottom: $space * 2.5;
    z-index: 2;

    @include bp(medium) {
      padding-top: 0;
      padding-bottom: 0;
      border-top: 3px solid $color-text;
    }

    @include bp-between(medium, large) {
      display: flex;
      align-items: center;
      border-bottom: 1px dashed $color-slate-200;
    }

    @include bp(large) {
      margin-top: $space;
    }

    .owl-nav {
      @include bp-between(medium, 900px) {
        right: 13%;
      }

      @include bp-between(900px, large) {
        right: 22.5%;
      }
    }
  }
}
