/* ==========================================================================
   #HR
   ========================================================================== */

hr {
  display: block;
  width: 45px;
  height: 0;
  margin-top: $space * 3;
  margin-bottom: $space * 3;
  border-top: 0;
  border-bottom: 2px solid $color-purple;

  @include bp(medium) {
    margin-top: $space * 6;
    margin-bottom: $space * 6;
  }
}