.c-learn__link {
  display: block;
  text-decoration: none;

  &:hover,
  &.active {
    color: $color-purple;
  }
}

.c-learn__content {
  display: none;

  &.active {
    display: block;
  }
}
