$nav-height: 56px;

.c-post__nav-wrapper {
  position: relative;
  flex: 1;
  height: $nav-height;
  overflow: hidden;
}

.c-post__nav {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  transform: translateY(0);
  transition: $global-transition;

  @include bp(medium) {
    display: flex;
    flex-direction: column;
  }
}

.c-post__nav-item {
  display: flex;
  align-items: center;
  height: $nav-height;
  margin: 0;
  padding-left: $space;
  padding-right: $space;
}

.c-post__nav-item--inside {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $color-text;
  font-size: 15px;
  line-height: 18px;

  @include bp(medium) {
    font-weight: 600;
  }

  .label {
    @extend .u-small-text;
    display: inline-block;
    flex: 0 0 $space * 5.5;
    width: $space * 5.5;
    font-weight: normal;
    line-height: 14px;

    @include bp(medium) {
      flex: 0 0 auto;
      width: auto;
      color: $color-medium-grey;
    }
  }
}

.c-post__nav--share {
  pointer-events: none;
  transition: $global-transition;

  @include bp(medium) {
    opacity: 0;
  }

  &.active {
    pointer-events: auto;
    opacity: 1;
  }

  path {
    fill: $color-black;
  }

  a {
    opacity: 0.4;
    transition: $global-transition;

    &:hover {
      opacity: 1;
    }
  }

  @include bp(medium) {
    .label {
      padding-right: $space / 2;
    }

    .addtoany_list a,
    .widget .addtoany_list a {
      padding: 4px;
    }
  }
}

.c-post__nav--title {
  color: $color-slate-500;

  @include bp(medium) {
    order: 1;
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.01em;
  }
}

.c-post__nav--next {
  @include bp(medium) {
    order: 2;
  }
}

.c-post__nav--next-text {
  padding-left: $space / 2;

  @include bp(medium) {
    padding-bottom: 2px;
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.01em;
  }
}

.c-post__nav--cat {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 19px;
  line-height: 23px;

  @include bp(medium) {
    order: 0;
  }
}

.c-post__nav--cat-text {
  padding-left: $space;
  font-weight: 900;
  color: $color-slate-500;

  @include bp(medium) {
    padding-left: 0;
  }
}

.addtoany_list a:not(.addtoany_special_service)>span {
  left: -9999px;
}

.addtoany_list a:not(.addtoany_special_service)>span {
  display: block !important;
}
