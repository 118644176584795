.o-grid__row {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: $space * 1.5;
  padding-right: $space * 1.5;

  @include bp(medium) {
    max-width: 720px;
    padding-left: 0;
    padding-right: 0;
  }

  @include bp(xmedium) {
    max-width: 720px;
  }

  @include bp(large) {
    max-width: 1200px;
  }
}

.o-grid__row_nop {
  @include max-width(medium) {
    padding-left: 0;
    padding-right: 0;
  }
}

.o-grid__col {
  padding-left: 10px;
  padding-right: 10px;

  @include bp-down(xmedium) {
    padding-left: 0;
    padding-right: 0;
  }
}

.o-grid_pl {
  padding-left: 10px;

  @include max-width(medium) {
    padding-left: 0;
  }
}

@for $i from 1 through 12 {
  .o-grid__col--#{$i} {
    width: 100% / 12 * $i;
  }
}

@for $i from 1 through 12 {
  .o-grid__col-xlarge--#{$i} {
    @include max-width(xlarge) {
      width: 100% / 12 * $i;
    }
  }
}

@for $i from 1 through 12 {
  .o-grid__col-large--#{$i} {
    @include max-width(large) {
      width: 100% / 12 * $i;
    }
  }
}

@for $i from 1 through 12 {
  .o-grid__col-medium--#{$i} {
    @include max-width(large) {
      width: 100% / 12 * $i;
    }
  }
}

@for $i from 1 through 12 {
  .o-grid__col-mobile--#{$i} {
    @include max-width(medium) {
      width: 100% / 12 * $i;
    }
  }

  .o-grid__push--#{$i} {
    margin-left: 100% / 12 * $i;
  }
}

.o-grid__col-mobile--12 {
  @include bp-down(medium) {
    padding-left: 0;
    padding-right: 0;
  }
}

.o-grid__push-large--0 {
  @include bp-down(large) {
    margin-left: 0;
  }
}

.o-grid--left {
  justify-content: flex-start;
}

.o-grid--right {
  justify-content: flex-end;
}

.o-grid-medium--center {
  @include max-width(medium) {
    justify-content: center;
  }
}

.o-grid__push-medium-push--2 {
  @include min-width-max-width(medium, large) {
    margin-left: 100% / 12 * 2;
  }
}

.o-grid__push-mobile-push--1 {
  @include max-width(medium) {
    margin-left: 100% / 12 * 1;
  }
}

// custom grid
// .o-grid__col--6,
// .o-grid__col-mobile--12.o-grid__col--3 {
//   @include bp-between(medium, large) {
//     width: 67%;
//     margin-left: 16.5%;
//   }
// }
