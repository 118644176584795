/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */

$input-height: 48px;

.c-post {
  @include clearfix();
  // overflow: hidden;
  padding-top: 56px;

  @include max-width(xlarge) {
    padding-top: 48px;
    padding-left: 20px;
    padding-right: 20px;
  }

  @include max-width(medium) {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .o-grid__row.post-content {
    @include min-width-max-width(medium, large) {
      max-width: 960px;
    }
  }
}

.alignnone {
  margin: $space * 2 $space * 2 $space * 2 0;
}

.aligncenter {
  margin: $space * 2 auto;
}

.alignright {
  float: right;
  margin: $space * 2 0 $space * 2 $space * 2;
}

.alignleft {
  float: left;
  margin: $space * 2 $space * 2 $space * 2 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}

.c-post__hero {
  display: flex;
  flex-direction: column-reverse;

  @include max-width(medium) {
    flex-direction: column;
  }

  img {
    @include bp-down(medium) {
      // margin-left: -$space;
      //  margin-right: -$space;
      // max-width: calc(100% + #{$space * 2});
    }
  }
}

.c-post__hero-thumbnail {
  position: relative;
  width: 1200px;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;

  img {
    @include bp(medium) {
      width: 100%;
    }
  }
}

.c-post__hero-thumbnail-caption {
  @extend .u-small-text;
  display: none;

  @include bp(large) {
    position: absolute;
    left: 100%;
    top: 0;
    display: block;
    color: $color-purple;
    white-space: nowrap;
    transform: rotate(90deg) translateY(-$space);
    transform-origin: 0 0;
  }
}

.c-post__title {
  font-family: $font-sans-serif;
  font-size: 48px;
  font-weight: 600;
  letter-spacing: -0.03em;
  line-height: 1.1;
  margin-block-end: 0;

  @include max-width(xlarge) {
    font-size: 42px;
  }

  @include max-width(large) {
    font-size: 36px;
  }

  @include max-width(medium) {
    margin-bottom: 0;
    margin-top: 32px;
  }

}

.c-post__content h2 {
  font-size: 36px;
  line-height: 1.25;

  @include max-width(large) {
    font-size: 30px;
  }
}

.c-post__content p {
  font-size: 21px;
  line-height: 31.5px;

  @include max-width(large) {
    font-size: 19px;
    line-height: 28.5px;

  }
}

.c-post__excerpt {
  font-size: 24px;
  line-height: 150%;

  color: $color-medium-grey;

  @include max-width(large) {
    font-size: 21px;
  }
}

.c-post__lower {
  padding-top: $space * 4;
  padding-bottom: $space;

  @include max-width(xlarge) {
    padding-top: 32px;
  }

  @include max-width(large) {
    padding-top: 24px;
  }

  @include max-width(medium) {
    padding-top: 24px;
    padding-bottom: $space * 6;
  }
}

.c-post__lower--share {
  max-width: 170px;
  // margin-top: $space;
  // margin-bottom: $space * 4;

  @include max-width(xlarge) {
    //margin-bottom: $space * 3.2;
  }

  @include max-width(large) {
    // margin-bottom: $space * 2.4;
  }

  @include max-width(medium) {
    // margin-bottom: 0;
  }

  .addtoany_shortcode {
    display: block;
    width: 100%;
  }

  .a2a_kit {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .addtoany_list a:not(.addtoany_special_service)>span {
    width: 30px !important;
    height: 30px !important;
    line-height: 30px !important;
  }

  svg path {
    fill: $color-slate-200;
  }
}

.c-post__content {
  a {
    display: inline;
    font-weight: 600;
    text-decoration: none;
    color: inherit;
    color: $color-blue;

    &:hover {
      text-decoration: underline;
    }
  }

  .addtoany_content {
    display: none // to be removed
  }
}

.c-post__lower--author {
  margin-top: $space * 2;
  // margin-bottom: $space * 2;

  @include bp-up(medium) {
    margin-bottom: 24px;
  }

  @include bp-up(large) {
    margin-bottom: 32px;
  }

  @include bp-up(xlarge) {
    margin-bottom: 40px;
  }

  @include bp(medium) {
    margin-top: $space;
    //margin-bottom: 0;
  }

  @include bp-between(medium, large) {
    display: block;
  }
}

.c-post__author {
  @extend .u-link--small;
  margin: 0;
  line-height: 0;

  @include bp-between(medium, large) {
    margin-top: $space;
  }

  a {
    @extend .u-link-purple;
    @extend .u-link--small;

  }
}

.c-post__date {
  @extend .u-small-text;
  font-weight: normal;
  color: $color-medium-grey;
}

.c-post__author-pic-wrapp {
  flex: 0 0 46px;
  width: 46px;
  padding-right: $space;
}

.c-post__author-pic {
  display: block;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;

  img {
    display: block;
    max-width: 100%;
  }
}

.c-post__related {
  position: relative;
  margin-bottom: $space * 2.5;

  @include bp(large) {
    position: absolute;
    left: 0;
    margin-top: $space * -5;
    margin-bottom: 0;
  }

  &.left {
    left: 0;
  }

  &.right {
    left: auto;
    right: 0;

    .c-post__related-title {
      @include bp-between(medium, xlarge) {
        margin-left: 0;
      }
    }
  }
}

.c-post__related-post--small {
  position: relative;
  max-width: 420px;
  min-height: 90px;
  padding-left: 110px;
}

.c-post__related-post {
  @extend .c-post__related-post--small;

  @include bp(small) {
    max-width: 475px;
    min-height: 120px;
    padding-left: 145px;
  }

  @include bp(medium) {
    max-width: 280px;
    min-height: 0;
    padding-left: $space;
  }

  @include bp(xlarge) {
    padding-left: 0;
  }
}

.c-post__related-thumb {
  display: block;
  overflow: hidden;
  text-align: center;

  img {
    height: 100%;
    width: auto;

    @include bp(medium) {
      max-width: 100%;
      height: auto;
    }
  }
}

.rp4wp-img {
  position: absolute;
  left: 0;
  top: 0;
  height: 70px;
}

.rp4wp_component_image {
  @extend .rp4wp-img;

  @include bp(small) {
    height: 90px;
  }

  @include bp(medium) {
    position: relative;
    width: auto;
    height: auto;
  }

  a {
    @extend .c-post__related-thumb;
    height: 100%;
  }
}

.c-post__related-title {
  position: relative;
  display: inline-block;
  margin-bottom: $space / 2;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.02em;

  @include bp(small) {
    display: block;
    margin-left: -$space;
    margin-bottom: $space * -1.5;
    font-family: 'Glosa Display';
    font-size: 54px;
    line-height: 67px;
    color: $color-very-light-grey;
  }

  @include bp(medium) {
    margin-bottom: $space * -1.9;
    font-size: 56px;
    line-height: 70px;
  }

  @include bp(xlarge) {
    font-size: 66px;
    line-height: 82px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 100%;
    top: 50%;
    display: block;
    width: 24px;
    height: 1px;
    margin-left: $space;
    background-color: currentColor;

    @include bp(medium) {
      display: none;
    }
  }
}

.c-post__related-post-title {
  display: inline-block;
  font-weight: 600;
  font-size: 19px;
  line-height: 23px;
  letter-spacing: -0.01em;
  color: $color-black;
}

.rp4wp_component_title {
  @extend .c-post__related-post-title;
}

.c-post__related-cat {
  margin-bottom: $space;
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $color-light-grey;

  @include bp(medium) {
    margin-top: $space * 1.5;
  }
}

.rp4wp_component_taxonomy {
  @extend .c-post__related-cat;
}

.c-post__subscribe {
  position: relative;

  @include bp-down(large) {
    width: 100%;
    margin-top: 0;
    margin-bottom: $space * 3;
  }

  @include bp(large) {
    position: absolute;
  }

  .gfield_label {
    display: none;
  }

  .ginput_container_email {
    margin-top: $space * 2;
    padding-right: 54px;
    box-shadow: 0 12px 12px rgba($color-black, 0.12);
  }

  .ginput_container_email input {
    height: $input-height;
    font-size: 15px;
    background-color: $color-white;
  }

  .ginput_container_email input::placeholder {
    font-size: 15px;
  }

  .ginput_container_email input::-webkit-placeholder {
    font-size: 15px;
  }

  .ginput_container_email input::-moz-placeholder {
    font-size: 15px;
  }

  .ginput_container_email input::-ms-placeholder {
    font-size: 15px;
  }


  .gform_footer {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 54px;
    height: $input-height;
    z-index: 2;
  }

  .gform_footer input {
    width: 100%;
    height: $input-height;
    background: $color-purple;
    color: transparent;
    text-indent: -9999px;
    white-space: nowrap;
    cursor: pointer;
  }

  .validation_error {
    position: absolute;
    left: -9999px;
  }

  .validation_message {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: $space / 2;
    font-size: 15px;
  }

  .gform_ajax_spinner {
    position: absolute;
    left: 50%;
    top: 100%;
    margin-top: $space / 2;
    transform: translateX(-50%);
  }
}

.c-post__subscribe-content {
  @include bp(large) {
    max-width: 225px;
  }

  @include bp(xlarge) {
    max-width: 270px;
  }
}

.c-post__subscribe-title {
  margin-bottom: $space * 1.5;
  font-size: 21px;
  font-weight: bold;
  line-height: 25px;
  letter-spacing: -0.02em;
  color: $color-purple;

  @include bp(large) {
    max-width: 260px;
  }
}

.c-post__subscribe-text {
  font-size: 17px;
  line-height: 20px;
  color: $color-medium-grey;

  @include bp(large) {
    max-width: 245px;
  }
}

.c-post__box {
  margin-top: 0;
  padding: $space * 2 $space * 3 $space * 2.5;
  font-size: 24px;
  font-weight: bold;
  line-height: 29px;
  letter-spacing: -0.02em;

  @include max-width(large) {
    position: relative;
    margin-top: $space * 3;
    margin-bottom: $space * 3;
  }

  &:after {
    content: '';
    position: absolute;
    left: $space * 5;
    top: 0;
    display: block;
    width: 80%;
    height: 100%;
    border: 2px solid $color-purple;
    z-index: 1;
  }
}

.c-post__box-content {
  position: relative;
  padding-top: $space * 1.6;
  padding-bottom: $space * 1.9;
  background-color: white;
  z-index: 2;

  a {
    color: $color-purple;

    @include bp(small) {
      background: -webkit-linear-gradient(180deg, #4840BB 30%, #8F53D7 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  a:after {
    @extend .icomoon;
    content: "\e908";
    margin-left: $space / 2;
    font-size: 8px;
    vertical-align: middle;
  }
}

.c-post__questions {
  .c-post__related-title {
    line-height: 1;
  }
}

.c-post__questions-list {
  position: relative;
  margin: 0;
  padding-right: 10px;
  list-style: none;
  z-index: 1;
}

.c-post__questions-list-item {
  position: relative;
  padding: 15px 0;
  margin: 0;

  &:after {
    content: '';
    position: absolute;
    left: 42px;
    right: 0;
    bottom: 0;
    display: block;
    height: 1px;
    background-color: $color-slate-100;
  }
}

.c-post__questions-list-title {
  position: relative;
  padding-left: 42px;
  font-size: 15px;
  letter-spacing: -0.02em;
  font-weight: 600;

  &:before,
  span {
    position: absolute;
    left: 0;
    top: -2px;
    display: block;
    font-weight: 600;
    letter-spacing: -0.02em;
    line-height: 1;
    color: $color-purple;
    background: -webkit-linear-gradient(270deg, #4840BB 30%, #8F53D7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  span {
    font-size: 36px;
  }

  &:before {
    content: 'Q';
    font-size: 48px;
  }
}

.numbered .c-post__questions-list-title:before {
  display: none;
}

.c-post__collection {
  .c-post__related-title {
    @include bp(large) {
      margin-bottom: $space * -3.5;
    }
  }

  .c-post__related-post {
    @extend .c-post__related-post--small;
  }

  .rp4wp_component_image {
    @extend .rp4wp-img;

    a img {
      height: 100%;
      width: auto;
      max-width: 400px;
    }
  }

  .rp4wp_component_title {
    font-size: 15px;
  }
}

.c-post__collection-subtitle {
  position: relative;
  max-width: 240px;
  font-size: 21px;
  line-height: 25px;
  letter-spacing: -0.02em;
  z-index: 1;
}

.c-post__related-tags,
.c-post__disclosure {
  font-size: 17px;
  line-height: 150%;
  padding-top: $space * 2.5;
  padding-bottom: $space * 2.5;
  border-top: 2px solid $color-slate-500;

  p {
    color: $color-slate-300;
  }

  .c-post__disclosure--heading {
    margin-bottom: $space * 2;
    color: $color-slate-500;
  }

  @include max-width(large) {
    font-size: 17px;
  }
}

.c-post__disclosure--small {
  padding-top: 0;
  font-size: 15px;
  border-top: none;
}

.c-post__related-tags {
  border-top: 1px solid $color-slate-100;
}

.c-post__related-tags--link {
  text-decoration: none;
  color: $color-purple;
  font-weight: 500;
}



.c-post__meta {
  @include bp-down(medium) {
    padding-left: 0;
    padding-right: 0;
  }

}


.wfb_cta {
  align-items: center;
  color: #161338;
  display: flex;
  font-family: Calibre;
  font-size: 19px;
  font-weight: 500;
  letter-spacing: -0.02em;
  line-height: 22px;

  @media(max-width: 759.98px) {
    margin-bottom: 15px;
  }
}

/*#wfb_subscription-input-field {
  border: none;
  padding: 0;
}*/

.wfb_subscription-button {
  background-color: #F3F3F3;
  border: none;
  color: rgba(22, 19, 56, 0.6);
  font-family: Calibre;
  font-size: 19px;
  line-height: 24px;

  &:active,
  &:focus,
  &:hover {
    outline: none;
    color: #4840BB;
  }
}

/*#wfb_subscription-button:active,
#wfb_subscription-button:focus,
#wfb_subscription-button:hover {
  outline: none;
  color: #4840BB;
}*/

.wfb_hide {
  display: none;
}

.wfb_input-field-error-message {
  align-items: center;
  background-color: #fff;
  border: 0.5px solid #000000;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  bottom: -68px;
  box-shadow: 0 2px 5px #CCC;
  color: #161338;
  justify-content: center;
  line-height: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-top: 15px;
  padding: 8px;
  position: absolute;
  text-align: center;
  width: 240px;

  &:before {
    border: 10px solid transparent;
    border-bottom-color: white;
    bottom: 100%;
    box-sizing: content-box;
    content: "";
    height: 0;
    left: 31px;
    margin-left: -10px;
    position: absolute;
    width: 0;
  }

  &:after {
    background-color: #ffffff;
    border: 0.5px solid #000000;
    bottom: 100%;
    box-sizing: content-box;
    content: "";
    height: 20px;
    left: 30px;
    margin-left: -10px;
    margin-bottom: -15px;
    position: absolute;
    width: 20px;
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: -1;
  }

  &.wfb_show {
    display: flex;
  }
}

/*#wfb_input-field-error-message:before {
  border: 10px solid transparent;
  border-bottom-color: white;
  bottom: 100%;
  box-sizing: content-box;
  content: "";
  height: 0;
  left: 31px;
  margin-left: -10px;
  position: absolute;
  width: 0;
}

#wfb_input-field-error-message:after {
  background-color: #ffffff;
  border: 0.5px solid #000000;
  bottom: 100%;
  box-sizing: content-box;
  content: "";
  height: 20px;
  left: 30px;
  margin-left: -10px;
  margin-bottom: -15px;
  position: absolute;
  width: 20px;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: -1;
}

#wfb_input-field-error-message.wfb_show {
  display: flex;
}*/

.wfb_input-field-success-message,
.wfb_input-field-error-message {
  display: none;
}

/*.wfb_input-field-success-message.wfb_show {
  display: block;
}*/

.wfb_input-field-success-message {
  border-bottom: 1px solid #E7E7EA;
  border-top: 1px solid #E7E7EA;
  color: #161338;
  font-size: 21px;
  padding: 32px 0;
  text-align: center;

  &.wfb_show {
    display: block;
  }

  @media(max-width: 759.98px) {
    padding: 20px 40px;
  }
}

.wfb-learn-more-button {
  background-color: #4840BB;
  color: #ffffff;
  font-weight: 500;
  margin: 0 auto 56px auto;
  padding: 16px 40px;
  text-align: center;
}

.wfb-learn-more-button a {
  color: #ffffff;
  text-decoration: none;
}

.wfb_subscription-input {
  background-color: #F3F3F3;
  display: grid;
  grid-template-columns: auto 50px;
  margin: 0;
  position: relative;
  transition: box-shadow 0.2s linear;
  width: 100%;

  &:hover,
  &:focus-within {
    box-shadow: 0 0 0 4px rgba(22, 19, 56, 0.1);
    transition: box-shadow 0.2s linear;
  }
}

/*
}

.wfb_subscription-input:hover,
.wfb_subscription-input:focus-within {
  box-shadow: 0px 0px 0px 4px rgba(22, 19, 56, 0.1);
  transition: box-shadow 0.2s linear;
}*/

.wfb_subscription-input-container {
  border-bottom: 1px solid #E7E7EA;
  border-top: 1px solid #E7E7EA;
  display: grid;
  grid-template-columns: 206px auto;
  /* margin-left: 20px;*/
  padding: 32px 0;

  @media(max-width: 759.98px) {
    display: block;
    margin-left: 0;
  }

  &.wfb_hide {
    display: none;
  }
}

.wfb_subscription-input-field {
  background-color: #F3F3F3;
  color: #161338;
  font-family: Calibre;
  font-size: 19px;
  line-height: 24px;
  padding: 8px 16px;
  border: none;
  //padding: 0;

  &::placeholder {
    color: #747386;
  }

  &:focus {
    outline: none;
  }

  @media(max-width: 759.98px) {
    margin-left: 0;
  }
}

/*#wfb_subscription-input-field::placeholder {
  color: #747386;
}

#wfb_subscription-input-field:focus {
  outline: none;
}*/

.wfb_subscription-section-container {
  color: #4840BB;
  font-family: Calibre;
  font-size: 21px;
  line-height: 31px;
  margin: 0 auto 20px;
}

.wfb_subscription-section-link {
  color: #4840BB;
  text-decoration: none;
}

a.wfb_subscription-section-link:hover,
.wfb_subscription-section:hover {
  color: #4840BB;
  cursor: pointer;
  opacity: 0.8;
}

.wfb_learn-more-button-container {
  display: flex;
  margin-top: 30px;
  padding-left: 20px;
}

@media(max-width: 759.98px) {

  .wfb_subscription-sections {
    flex-direction: column;
    padding-left: 0;
  }


  /* #wfb_subscription-input-field {
    margin-left: 0;
  }

  #wfb_input-field-success-message {
    padding: 20px 40px;
  }

  #wfb_subscription-input-container {
    display: block;
    margin-left: 0;
  }

  #wfb_cta {
    margin-bottom: 15px;
  }*/

  .wfb_learn-more-button-container {
    padding-left: 0;
  }
}

.c-post hr {
  margin-top: 40px;
  margin-bottom: 40px;
}

.social-media-icons-container {



  margin: 0 !important;
  position: absolute;
  top: 46px;
  left: 0;
  bottom: 30px;

  .social-media-icons {
    position: sticky;
    top: 120px;
  }

  .addtoany_list {
    display: flex;
    flex-direction: column;

    @include max-width(medium) {
      display: none;
    }

    a {
      font-size: 32px;
      padding: 0;
    }

    a:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  @include max-width(large) {
    top: 32px;
  }
}
