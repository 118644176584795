/* ==========================================================================
   #HIDE
   ========================================================================== */

/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */

.u-hidden-visually {
  @include hidden-visually();
}

/**
 * Hide visually and from screen readers.
 */

.u-hide__mobile {
  @include bp-down(medium) {
    display: none !important;
  }
}

.u-hide__desktop {
  @include bp(medium) {
    display: none !important;
  }
}