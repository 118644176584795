/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h1 {
  font-family: $font-serif;
  margin-bottom: $space * 6;
  font-size: 42px;
  line-height: 1.1;
  font-weight: 900;

  @include bp(medium) {
    font-size: 48px;
  }

  @include bp(large) {
    font-size: 60px;
  }

  @include bp(xlarge) {
    font-size: 72px;
  }
}

h2 {
  margin-top: $space * 3;
  margin-bottom: $space * 3;
  font-size: 30px;
  line-height: 1.25;
  font-weight: 600;

  @include bp(medium) {
    font-size: 36px;
    letter-spacing: -0.02em;
  }

  strong {
    font-weight: 600;
  }
}

h3 {
  font-size: 24px;
  font-weight: 600;
}

h4 {
  font-size: 1.25rem;
  font-weight: 600;
}

h5 {
  font-size: 1.125rem;
  font-weight: 600;
}

h6 {
  font-size: 1rem;
  font-weight: 600;
}
