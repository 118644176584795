.post-template-single-wf-branded {
  .c-header {
    background-color: $color-purple;

    path {
      fill: white;
    }
  }

  .c-header__home:after {
    background-color: rgba($color-slate-100, 0.1);
  }

  .c-scrpr__wrapper {
    background-color: rgba(white, 0.2);
  }

  .c-main-nav__search,
  .c-main-nav__toggle {
    color: white;
  }

  .c-post__nav,
  .c-post__nav-item--inside,
  .c-post__nav--cat-text,
  .c-post__nav--title,
  .c-post__title {
    color: white;
  }


  .c-post__title {
    margin-bottom: $space * 3;

    @include bp(large) {
      margin-bottom: $space * 5;
    }
  }

  .c-post__nav-item--inside .label {
    color: $color-slate-100;
  }

  .c-post {
    padding-top: 0;
  }

  .c-post__hero {
    position: relative;
    display: block;
    background: none;

    @include bp(large) {
      padding-top: $space * 6;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: 80%;
      background: linear-gradient(162.16deg, $color-purple 54.54%, #8F53D7 100%);
      z-index: 0;
    }

    .o-grid__row {
      position: relative;
      z-index: 1;
    }
  }

  .c-post__hero.no-thumb {
    padding-bottom: $space * 3;

    @include bp(medium) {
      padding-bottom: $space * 10;
    }

    &:after {
      height: 100%;
    }
  }

  @include bp-down(medium) {
    .c-post__hero img {
      max-width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .c-post__hero-cat {
    @include bp-down(medium) {
      margin-top: $space * 4;
      margin-bottom: $space * 1.5;
    }

    .u-link {
      color: white;
    }
  }

  .c-post__excerpt {
    color: $color-slate-200;

    @include bp(medium) {
      font-size: 24px;
    }
  }

  .c-post__hero-thumbnail {
    margin-top: $space * 4;
  }

  hr {
    margin-top: $space * 1.5;
  }
}
