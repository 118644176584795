.c-post__content-note-wrapper {
  margin-bottom: $space * 2.5;

  @include bp(medium) {
    margin-bottom: 0;
  }

  &.left {
    @include bp(medium) {
      padding-right: $space * 1.5;
    }
  }

  &.right {
    @include bp(medium) {
      padding-left: $space * 1.5;
    }
  }
}

.c-post__content-note {
  margin-left: $space * -2.5;
  margin-right: $space * -2.5;
  padding: 30px 25px;
  background-color: rgba($color-purple, 0.1);

  @include bp(medium) {
    margin-left: 0;
    margin-right: 0;
  }
}

.c-post__content-note-title {
  @extend .u-small-text;
  margin-bottom: $space * 2;
  line-height: 14px;
  color: $color-purple;

  @include bp(medium) {
    text-align: center;
  }
}

.c-post__content .c-post__content-note-content,
.c-post__content-note-content {
  a {
    @extend .c-btn;
    @extend .c-btn--full;
    margin-top: $space * 2;

    &:hover {
      text-decoration: none;
    }
  }
}

.c-post__content-note-content {
  font-size: 19px;
  line-height: 1.25;

  ul {
    margin: 0;
    list-style: none;
  }

  li {
    position: relative;
    margin-bottom: $space * 1.5;
    padding-left: $space * 2.5;

    &:before {
      content: '•';
      position: absolute;
      left: 0;
      top: 0;
      color: $color-purple;
    }
  }

  p:last-child {
    @include bp-down(medium) {
      text-align: left !important;
    }

    a {
      margin-bottom: $space * -6;

      @include bp(medium) {
        margin-bottom: 0;
      }
    }
  }
}