.c-search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: $space * 7 $space * 2.4;
  background-color: white;
  transform: translateY(-100%);
  transition: $global-transition;
  z-index: 99;

  &.active {
    transform: translateY(0);
  }

  .searchform {
    max-width: 1200px;
    margin: 0 auto;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: -0.02em;
  }

  input {
    width: 100%;
    padding: $space 0;
    border: none;
    outline: none;
    border-bottom: 2px solid $color-slate-500;
  }
}

.c-search-overlay__close {
  position: absolute;
  top: $space * 1.5;
  right: $space * 1.5;
  padding: $space * 1.5;
  font-size: 15px;
  color: $color-slate-200;
}

.searchform input::-webkit-input-placeholder {
  color: $color-slate-200;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: -0.02em;
}

.searchform input::-moz-placeholder {
  color: $color-slate-200;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: -0.02em;
}

.searchform input:-ms-input-placeholder {
  color: $color-slate-200;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: -0.02em;
}

.searchform input:-moz-placeholder {
  color: $color-slate-200;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: -0.02em;
}

.searchform .submit {
  display: none;
}
