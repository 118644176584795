.c-block__gallery {
  margin-left: -$space * 2.5;
  margin-right: -$space * 2.5;
  margin: $space * 2 $space * -2.5;

  @include bp(medium) {
    margin-left: -50%;
    margin-right: -50%;
    margin-top: $space * 3;
    margin-bottom: $space * 3;
  }

  @include bp(large) {
    margin-left: -35%;
    margin-right: -35%;
  }

  @include bp(xlarge) {
    margin-left: -19%;
    margin-right: -19%;
    margin-top: $space * 6;
    margin-bottom: $space * 7;
  }

  &.width-wide {
    @include bp(large) {
      margin-left: -250px;
      margin-right: -250px;
    }

    @include bp(xlarge) {
      margin-left: -310px;
      margin-right: -310px;
    }
  }
}

.c-block__gallery-items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -$space;
  padding: 0;
  list-style: none;
}

.c-block__gallery-item figcaption {
  padding: 0 $space * 2.5;
  font-size: 15px;
  text-align: left;

  @include bp(medium) {
    padding: 0;
  }

  a {
    @extend .u-small-text;
    color: $color-purple;
  }

  p:first-child {
    padding-top: $space * 2.5;
  }
}

.c-block__gallery-item {
  padding: 0 $space;

  .columns-2 & {
    @include bp(small) {
      flex: 0 0 50%;
    }
  }

  .columns-3 & {
    @include bp(medium) {
      flex: 0 0 50%;
    }

    @include bp(xlarge) {
      flex: 0 0 33.333333%;
    }
  }
}

.columns-1 .c-block__gallery-item.caption-right {
  figure {
    @include bp(medium) {
      display: flex;
    }
  }

  img {
    @include bp(medium) {
      flex: 0 0 66.666666%;
      width: 66.666666%;
    }
  }

  figcaption {
    padding: 0 $space * 2.5;

    @include bp(medium) {
      flex: 0 0 33.333333%;
      width: 33.333333%;
      padding-right: 0;
    }
  }

  p:first-child {
    @include bp(medium) {
      border-top: 1px solid $color-slate-100;
    }
  }
}

.columns-3 .c-block__gallery-item:first-child {
  @include bp-between(medium, xlarge) {
    flex: 0 0 100%;

  }

  @include bp-between(large, xlarge) {
    figure {
      display: flex;
    }

    a {
      display: block;
    }
    
    img {
      flex: 0 0 66.666666%;
      width: 66.666666%;
    }

    figcaption {
      flex: 0 0 33.333333%;
      width: 33.333333%;
      padding-left: $space * 2.5;
    }

    p:first-child {
      border-top: 1px solid $color-slate-100;
    }
  }
}

.wp-block-gallery .blocks-gallery-image figcaption,
.wp-block-gallery .blocks-gallery-item figcaption {
  position: static;
  background: none;
  color: $color-medium-grey;
}

.wp-block-gallery .blocks-gallery-item figure {
  display: block;
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  width: calc((100% - 20px) / 2);
  margin: 0 $space * 2 $space * 2 0;
}

.wp-block-gallery figcaption s {
  text-decoration: none;
  text-transform: uppercase;
  font-size: $font-size-sm;
  color: $color-purple;
}
