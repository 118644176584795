/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */

blockquote {
  quotes: '“''”''‘''’';
  position: relative;
  margin: $space * 4 0 $space * 4 -$space;
  padding: $space * 1.3 0;
  padding-left: $space * 4;
  font-weight: bold;
  font-size: 30px;
  line-height: 1.25;
  letter-spacing: -0.02em;

  @include bp(medium) {
    padding-left: $space * 8;
  }

  @include bp(xmedium) {
    margin: $space * 3 -30% $space * 8;
    padding: $space * 3 0 0 $space * 8;
  }

  @include bp(large) {
    margin: $space -30% $space * 3;
  }

  @include bp(xlarge) {
    margin-top: $space * 3;
    margin-bottom: $space * 7;
  }

  @include max-width(large) {
    font-size: 24px;
  }


  &:before {
    content: '“';
    position: absolute;
    left: 0;
    top: 0;
    padding-right: $space;
    font-weight: bold;
    font-size: 72px;
    line-height: 1;
    color: rgba($color-text, 0.2);
    z-index: 4;

    @include bp(medium) {
      padding-right: $space * 2;
      font-size: 140px;
      color: $color-purple;
    }
  }

  &:after {
    content: '';
    position: absolute;
    right: -30px;
    top: 0;
    display: block;
    width: 93%;
    height: 100%;
    border: 2px solid $color-purple;
    z-index: 1;

    @include bp(medium) {
      display: none;
    }
  }

  p {
    position: relative;
    background-color: white;
    z-index: 2;
  }

  p:first-child {
    padding-top: $space * 1.5;

    @include bp(medium) {
      padding-top: 0;
    }
  }

  p:last-child {
    padding-bottom: $space * 1.5;

    @include bp(medium) {
      padding-bottom: 0;
    }
  }
}

cite {
  display: block;
  font-weight: normal;
  font-size: 21px;
  text-align: right;
}
