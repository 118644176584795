/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  color: $color-link;
  text-decoration: underline;

  &:hover {
    @include bp(medium) {
      color: $color-hover;
    }
  }
}
