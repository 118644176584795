.post-template-single-default-editorial {
  .c-post__title {
    @extend h1;
    font-family: $font-serif;
    font-weight: 900;
  }

  .c-post__content {
    >p:first-child:first-letter {
      margin-top: $space * -0.5;
      padding-right: $space * 1.5;
      float: left;
      font-family: $font-serif;
      font-weight: 900;
      font-size: 72px;
      line-height: 60px;
      font-weight: bold;
    }
  }

  .wp-block-image {
    max-width: 120%;
    margin-left: $space * -2.5;
    margin-right: $space * -2.5;

    @include bp(medium) {
      max-width: 140%;
      margin-left: -20%;
      margin-right: -20%;
    }

    figcaption {
      font-size: 15px;
      line-height: 18px;

      @include bp(medium) {
        padding-left: 14%;
        padding-right: 14%;
      }

      a {
        font-size: $font-size-sm;
        text-transform: uppercase;
        color: $color-purple;
      }
    }
  }
}
