.c-related__bottom {
  margin-top: $space * 5;
  padding-bottom: $space * 3;
  overflow: hidden;

  @include max-width(large) {
    margin-top: 0;
    max-width: 800px;
  }

  .c-post__related-title {
    display: none;
  }

  h3 {
    display: none;
  }

  .c-related_container {
    max-width: 1200px;

    @include max-width(medium) {
      max-width: 600px;
    }


    @include bp-up(medium) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @include bp-up(xlarge) {
      padding-left: 0;
      padding-right: 0;
    }


  }

  .c-archive__category-link.c-post__related-post {
    display: block;
    max-width: 350px;

    @include max-width(large) {
      align-items: flex-start;
      display: grid;
      grid-template-areas: "text image";
      grid-template-columns: 2fr 1fr;
      max-width: 800px;
    }

    @include bp-between(medium, large) {
      min-height: 210px;
      max-width: 100%;
      // padding-left: 320px;
    }

    @include max-width(medium) {
      display: block;
      max-width: 360px;
    }
  }

  .c-post__related-post_text {
    @include max-width(large) {
      grid-area: text;
    }
  }

  .c-archive__category-link.c-post__related-post .rp4wp_component_image {
    position: relative;
    width: 100%;
    height: auto;

    @include max-width(large) {
      grid-area: image;
      padding-left: 15px;
      margin-top: 40px;
      max-width: 100%;
    }

    @include bp-between(medium, large) {
      // position: absolute;
      // left: 0;
      //top: 0;
      //flex: 0 0 290px;
      //width: 290px;
      // height: 210px;
    }

    @include max-width(medium) {
      margin: 0;
      padding: 0;
    }

    img {
      left: 0;
      transform: none;
      width: 100%;
      height: auto;
    }
  }

  .c-archive__category-item-cat {
    color: #747386;
  }

  .rp4wp_component.rp4wp_component_title.rp4wp_component_4 {
    @extend h3;
    display: block;
    margin-top: $space / 2;
    font-family: $font-serif;
    font-weight: 900;
    line-height: 1.25;

    a {
      text-decoration: none;
      color: inherit;
    }
  }

  .c-archive__category-item-cat {
    margin-bottom: $space;
  }

  .c-post__related-post {
    padding-left: 0;
  }
}

.c-related__bottom-heading {
  display: flex;
  align-items: baseline;
  margin-bottom: 0;
  font-weight: 700;

  @include bp(large) {
    margin-bottom: $space;
    font-size: 48px;
  }

  .deco {
    flex: 1;
    display: block;
    height: 2px;
    margin-left: $space * 3;
    background-color: $color-slate-500;
  }
}

.c-related__bottom-grid-wrapper {
  @include bp-up(large) {
    width: calc(100% + 80px);
    position: relative;
    left: -40px;
  }
}

.c-related__bottom-grid {
  width: auto;
  min-width: 100%;
  max-width: 1500px;
  align-items: flex-start;

  @include bp-down(medium) {
    padding-left: 0;
    padding-right: 0;
  }

  @include bp(large) {
    // margin-left: $space * -3;
    // margin-right: $space * -3;
  }

  @include bp(xlarge) {
    //  margin-left: $space * -5.2;
    //  margin-right: $space * -5.2;
  }

  @include bp-up(large) {
    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.c-related-posts__item {
  border-bottom: 1px solid $color-slate-100;

  @include bp-down(large) {
    padding: 0 0 $space * 4 0;
  }

  @include bp-down(medium) {
    margin-top: $space * 3;
    padding: 0 0 $space * 3 0;
  }

  @include bp-up(large) {
    padding-left: 40px;
    padding-right: 40px;
    border-right: 1px solid;
    border-image:
      linear-gradient(to bottom,
        #e7e7ea,
        rgba(231, 231, 234, 0)) 1 100%;
  }

  @include bp(xlarge) {
    padding-left: 40px;
    padding-right: 40px;
  }

  &:last-child {
    border-right: 0;
    border-bottom: 0;
  }
}

.c-related-posts__item-read,
.c-related-posts__item-preview {
  font-size: 19px;
  line-height: 1.58;
  color: $color-slate-300;
}

.c-related-posts__item-read {
  @include bp-down(medium) {
    margin-top: $space;
  }

  @include bp(large) {
    margin-top: $space;
  }
}

.c-related-posts__item-read {
  display: none;

  @include bp(medium) {
    display: block;
    font-weight: 600;
    letter-spacing: -0.01em;
    color: $color-slate-500;
  }

  @include bp-down(large) {
    margin-top: $space;
  }
}
