.gform_fields {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.gfield {
  margin: 0;
}

.gform_validation_container {
  position: absolute;
  left: -9999px;
  visibility: hidden;
}

.ginput_container {
  input {
    width: 100%;
    padding-left: $space + 3;
    padding-right: $space + 3;
    font-size: 19px;
    line-height: 23px;
    color: $color-medium-grey;
  }

  ::-webkit-input-placeholder {
    font-size: 19px;
    line-height: 23px;
    color: $color-medium-grey;
  }
  ::-moz-placeholder {
    font-size: 19px;
    line-height: 23px;
    color: $color-medium-grey;
  }
  :-ms-input-placeholder {
    font-size: 19px;
    line-height: 23px;
    color: $color-medium-grey;
  }
  :-moz-placeholder {
    font-size: 19px;
    line-height: 23px;
    color: $color-medium-grey;
  }
}

.gform_wrapper {
  form {
    position: relative;
  }
}