body:not(.single-post) {
  // .c-header__inner {
  //   padding-left: 20px;
  //   padding-right: 20px;
  // }

  .c-main-nav__toggle {
    display: block;
    order: 100;
  }
}
