.c-search {
  padding: $space * 2 0;

  @include bp(medium) {
    padding: $space * 4 0;
  }

  @include bp(large) {
    padding: $space * 6 0;
  }

  .c-post__related-post {
    @include bp(medium) {
      padding-left: 0;
    }
  }
}

.c-search__title {
  padding-left: $space * 2;
  padding-right: $space * 2;
  font-family: $font-sans-serif;
  font-weight: normal;
  color: $color-slate-300;

  @include bp-down(medium) {
    margin-bottom: $space * 6;
  }

  @include bp(xlarge) {
    padding-left: 0;
    padding-right: 0;
  }

  span {
    display: block;
    font-weight: 600;
    color: $color-slate-500;
  }

  @include bp(large) {
    font-size: 60px;
  }
}

.c-search__items {
  position: relative;
  padding-top: $space * 2;

  @include bp(large) {
    padding-top: $space * 4;
  }

  @include bp(xlarge) {
    max-width: 1270px;
    padding-top: $space * 5.5;
  }
}

.c-search__items--decor {
  position: absolute;
  width: 100%;
  height: 300px;
  left: 0;
  top: 0;
  background: radial-gradient(at top center, #000000, rgba(255, 255, 255, 0) 50%);
  opacity: 0.1;
}

.c-search__load-more {
  margin-top: $space * 6;
  text-align: center;
}