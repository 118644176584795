/* ==========================================================================
   #FONT-FACE
   ========================================================================== */

/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */

// Calibre
@font-face {
  font-family: 'Calibre';
  src: url('../assets/fonts/Calibre/Calibre-ThinItalic.eot');
  src: url('../assets/fonts/Calibre/Calibre-ThinItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Calibre/Calibre-ThinItalic.woff2') format('woff2'),
      url('../assets/fonts/Calibre/Calibre-ThinItalic.woff') format('woff'),
      url('../assets/fonts/Calibre/Calibre-ThinItalic.svg#Calibre-ThinItalic') format('svg');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Calibre';
  src: url('../assets/fonts/Calibre/Calibre-Semibold.eot');
  src: url('../assets/fonts/Calibre/Calibre-Semibold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Calibre/Calibre-Semibold.woff2') format('woff2'),
      url('../assets/fonts/Calibre/Calibre-Semibold.woff') format('woff'),
      url('../assets/fonts/Calibre/Calibre-Semibold.svg#Calibre-Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url('../assets/fonts/Calibre/Calibre-Medium.eot');
  src: url('../assets/fonts/Calibre/Calibre-Medium.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Calibre/Calibre-Medium.woff2') format('woff2'),
      url('../assets/fonts/Calibre/Calibre-Medium.woff') format('woff'),
      url('../assets/fonts/Calibre/Calibre-Medium.svg#Calibre-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url('../assets/fonts/Calibre/Calibre-Thin.eot');
  src: url('../assets/fonts/Calibre/Calibre-Thin.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Calibre/Calibre-Thin.woff2') format('woff2'),
      url('../assets/fonts/Calibre/Calibre-Thin.woff') format('woff'),
      url('../assets/fonts/Calibre/Calibre-Thin.svg#Calibre-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url('../assets/fonts/Calibre/Calibre-RegularItalic.eot');
  src: url('../assets/fonts/Calibre/Calibre-RegularItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Calibre/Calibre-RegularItalic.woff2') format('woff2'),
      url('../assets/fonts/Calibre/Calibre-RegularItalic.woff') format('woff'),
      url('../assets/fonts/Calibre/Calibre-RegularItalic.svg#Calibre-RegularItalic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Calibre';
  src: url('../assets/fonts/Calibre/Calibre-LightItalic.eot');
  src: url('../assets/fonts/Calibre/Calibre-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Calibre/Calibre-LightItalic.woff2') format('woff2'),
      url('../assets/fonts/Calibre/Calibre-LightItalic.woff') format('woff'),
      url('../assets/fonts/Calibre/Calibre-LightItalic.svg#Calibre-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Calibre';
  src: url('../assets/fonts/Calibre/Calibre-Regular.eot');
  src: url('../assets/fonts/Calibre/Calibre-Regular.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Calibre/Calibre-Regular.woff2') format('woff2'),
      url('../assets/fonts/Calibre/Calibre-Regular.woff') format('woff'),
      url('../assets/fonts/Calibre/Calibre-Regular.svg#Calibre-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url('../assets/fonts/Calibre/Calibre-BlackItalic.eot');
  src: url('../assets/fonts/Calibre/Calibre-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Calibre/Calibre-BlackItalic.woff2') format('woff2'),
      url('../assets/fonts/Calibre/Calibre-BlackItalic.woff') format('woff'),
      url('../assets/fonts/Calibre/Calibre-BlackItalic.svg#Calibre-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Calibre';
  src: url('../assets/fonts/Calibre/Calibre-Black.eot');
  src: url('../assets/fonts/Calibre/Calibre-Black.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Calibre/Calibre-Black.woff2') format('woff2'),
      url('../assets/fonts/Calibre/Calibre-Black.woff') format('woff'),
      url('../assets/fonts/Calibre/Calibre-Black.svg#Calibre-Black') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url('../assets/fonts/Calibre/Calibre-Light.eot');
  src: url('../assets/fonts/Calibre/Calibre-Light.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Calibre/Calibre-Light.woff2') format('woff2'),
      url('../assets/fonts/Calibre/Calibre-Light.woff') format('woff'),
      url('../assets/fonts/Calibre/Calibre-Light.svg#Calibre-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url('../assets/fonts/Calibre/Calibre-SemiboldItalic.eot');
  src: url('../assets/fonts/Calibre/Calibre-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Calibre/Calibre-SemiboldItalic.woff2') format('woff2'),
      url('../assets/fonts/Calibre/Calibre-SemiboldItalic.woff') format('woff'),
      url('../assets/fonts/Calibre/Calibre-SemiboldItalic.svg#Calibre-SemiboldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Calibre';
  src: url('../assets/fonts/Calibre/Calibre-BoldItalic.eot');
  src: url('../assets/fonts/Calibre/Calibre-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Calibre/Calibre-BoldItalic.woff2') format('woff2'),
      url('../assets/fonts/Calibre/Calibre-BoldItalic.woff') format('woff'),
      url('../assets/fonts/Calibre/Calibre-BoldItalic.svg#Calibre-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Calibre';
  src: url('../assets/fonts/Calibre/Calibre-MediumItalic.eot');
  src: url('../assets/fonts/Calibre/Calibre-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Calibre/Calibre-MediumItalic.woff2') format('woff2'),
      url('../assets/fonts/Calibre/Calibre-MediumItalic.woff') format('woff'),
      url('../assets/fonts/Calibre/Calibre-MediumItalic.svg#Calibre-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Calibre';
  src: url('../assets/fonts/Calibre/Calibre-Bold.eot');
  src: url('../assets/fonts/Calibre/Calibre-Bold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Calibre/Calibre-Bold.woff2') format('woff2'),
      url('../assets/fonts/Calibre/Calibre-Bold.woff') format('woff'),
      url('../assets/fonts/Calibre/Calibre-Bold.svg#Calibre-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

// Glosa
@font-face {
  font-family: 'Glosa Display';
  src: url('../assets/fonts/Glosa/GlosaDisplay-BlackItalic.eot');
  src: url('../assets/fonts/Glosa/GlosaDisplay-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Glosa/GlosaDisplay-BlackItalic.woff2') format('woff2'),
      url('../assets/fonts/Glosa/GlosaDisplay-BlackItalic.woff') format('woff'),
      url('../assets/fonts/Glosa/GlosaDisplay-BlackItalic.svg#GlosaDisplay-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Glosa Display';
  src: url('../assets/fonts/Glosa/GlosaDisplay-BoldItalic.eot');
  src: url('../assets/fonts/Glosa/GlosaDisplay-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Glosa/GlosaDisplay-BoldItalic.woff2') format('woff2'),
      url('../assets/fonts/Glosa/GlosaDisplay-BoldItalic.woff') format('woff'),
      url('../assets/fonts/Glosa/GlosaDisplay-BoldItalic.svg#GlosaDisplay-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Glosa Display';
  src: url('../assets/fonts/Glosa/GlosaDisplay-Bold.eot');
  src: url('../assets/fonts/Glosa/GlosaDisplay-Bold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Glosa/GlosaDisplay-Bold.woff2') format('woff2'),
      url('../assets/fonts/Glosa/GlosaDisplay-Bold.woff') format('woff'),
      url('../assets/fonts/Glosa/GlosaDisplay-Bold.svg#GlosaDisplay-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Glosa Display Roman';
  src: url('../assets/fonts/Glosa/GlosaDisplay-RomanItalic.eot');
  src: url('../assets/fonts/Glosa/GlosaDisplay-RomanItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Glosa/GlosaDisplay-RomanItalic.woff2') format('woff2'),
      url('../assets/fonts/Glosa/GlosaDisplay-RomanItalic.woff') format('woff'),
      url('../assets/fonts/Glosa/GlosaDisplay-RomanItalic.svg#GlosaDisplay-RomanItalic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Glosa Display';
  src: url('../assets/fonts/Glosa/GlosaDisplay-MediumItalic.eot');
  src: url('../assets/fonts/Glosa/GlosaDisplay-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Glosa/GlosaDisplay-MediumItalic.woff2') format('woff2'),
      url('../assets/fonts/Glosa/GlosaDisplay-MediumItalic.woff') format('woff'),
      url('../assets/fonts/Glosa/GlosaDisplay-MediumItalic.svg#GlosaDisplay-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Glosa Display';
  src: url('../assets/fonts/Glosa/GlosaDisplay-Black.eot');
  src: url('../assets/fonts/Glosa/GlosaDisplay-Black.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Glosa/GlosaDisplay-Black.woff2') format('woff2'),
      url('../assets/fonts/Glosa/GlosaDisplay-Black.woff') format('woff'),
      url('../assets/fonts/Glosa/GlosaDisplay-Black.svg#GlosaDisplay-Black') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Glosa Display Roman';
  src: url('../assets/fonts/Glosa/GlosaDisplay-Roman.eot');
  src: url('../assets/fonts/Glosa/GlosaDisplay-Roman.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Glosa/GlosaDisplay-Roman.woff2') format('woff2'),
      url('../assets/fonts/Glosa/GlosaDisplay-Roman.woff') format('woff'),
      url('../assets/fonts/Glosa/GlosaDisplay-Roman.svg#GlosaDisplay-Roman') format('svg');
  font-weight: normal;
  font-style: normal;
}

// icomoon
@font-face {
  font-family: 'icomoon';
  src:  url('../assets/fonts/icomoon/fonts/icomoon.eot?9l67kf');
  src:  url('../assets/fonts/icomoon/fonts/icomoon.eot?9l67kf#iefix') format('embedded-opentype'),
    url('../assets/fonts/icomoon/fonts/icomoon.ttf?9l67kf') format('truetype'),
    url('../assets/fonts/icomoon/fonts/icomoon.woff?9l67kf') format('woff'),
    url('../assets/fonts/icomoon/fonts/icomoon.svg?9l67kf#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icomoon,
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-menu:before {
  content: "\e900";
}
.icon-zoom:before {
  content: "\e901";
}
.icon-close:before {
  content: "\e902";
}
.icon-arrow:before {
  content: "\e903";
}
.icon-chevron-left:before {
  content: "\e90a";
}
.icon-chevron-right:before {
  content: "\e904";
}
.icon-twitter:before {
  content: "\e905";
}
.icon-facebook:before {
  content: "\e906";
}
.icon-email:before {
  content: "\e907";
}
.icon-arrow-right:before {
  content: "\e908";
}
.icon-arrow-right-2:before {
  content: "\e909";
}
