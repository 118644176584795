.archive {
  .c-main-nav__search {
    margin-left: auto;
  }

  &.category-product-news,
  &.subcategory {
    .c-archive__old {
      .c-archive__featured-wrapper:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 45%;
        display: block;
        width: 100%;
        height: 175%;
        background: linear-gradient(158.08deg, $color-purple 54.54%, #8F53D7 100%);
        z-index: -1;

        @include bp(small) {
          bottom: 40%;
        }

        @include bp(medium) {
          bottom: 10%;
        }

        @include bp(large) {
          height: 155%;
          bottom: 15%;
        }

        @include bp(xlarge) {
          height: 135%;
          bottom: 35%;
        }
      }
    }
  }
}

.c-archive__featured-wrapper {
  position: relative;
  margin-bottom: $space * 3;
}

.c-archive__essentials-row {
  width: calc(100% + 20px);
  margin-left: -$space;
  margin-right: -$space;
}

.c-archive {
  overflow: hidden;


  .c-archive__featured-wrapper {
    @include bp(xlarge) {
      width: 66.66667%;
    }
  }

  .c-archive__essentials-title {
    position: static;
    transform: none;
  }

  .c-archive__news {
    position: relative;
    padding-top: $space * 0.5;
    overflow: hidden;
  }

  .c-archive__essentials-title {
    margin-bottom: $space * 2.5;
  }

  .c-archive__featured-wrapper {
    @include bp-down(large) {
      width: 100%;
    }
  }

  .c-archive__featured--mark {
    display: none;
  }

  .c-archive__featured-text {
    @include bp-between(medium, large) {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 65%;
      margin-left: auto;
      margin-top: 0;
      padding: $space * 3;
      background-color: white;
      transform: translateY(#{$space * 4.5}); // includes padding and baseline adjustment
    }
  }

  .c-archive__featured-img {
    @include bp-between(medium, large) {
      max-width: 90%;
    }
  }
}

.c-archive__old {
  .c-archive__featured {
    padding-bottom: 0;
    overflow: hidden;

    @include bp-down(medium) {
      margin: 0;
      padding-left: $space * 3;
      padding-right: 0;
    }

    &:after {
      display: none;
    }
  }

  .c-archive__featured-cat:before {
    @include bp-down(medium) {
      display: none;
    }
  }

  .c-archive__featured-wrapper {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .c-archive__featured-img {
    margin-left: 0;

    @include bp(medium) {
      width: 79%;
    }

    @include bp(large) {
      width: 66%;
    }

    @include bp(xlarge) {
      width: 57%;
    }
  }

  .c-archive__featured-text {
    @include bp(large) {
      right: $space * 6;
    }

    @include bp(xlarge) {
      left: 57%;
      right: auto;
      bottom: 50%;
      width: 43%;
      transform: translateY(50%);
    }
  }

  .c-archive__featured-title {
    font-family: $font-sans-serif;
    font-size: 24px;
    font-weight: 600;

    @include bp(medium) {
      font-size: 36px;
    }
  }

  .c-archive__featured--mark,
  .c-archive__featured-title i {
    display: none;
  }

  .c-archive__news {
    position: relative;
    padding-top: $space * 2.5;
    padding-bottom: $space * 2.5;
    z-index: 2;

    @include bp(medium) {
      padding-top: 0;
      padding-bottom: 0;
      border-top: 3px solid $color-text;
    }

    @include bp-between(medium, large) {
      display: flex;
      align-items: center;
      border-bottom: 1px dashed $color-slate-200;
    }

    @include bp(large) {
      margin-top: $space;
    }

    .owl-nav {
      @include bp-between(medium, 900px) {
        right: 13%;
      }

      @include bp-between(900px, large) {
        right: 22.5%;
      }
    }
  }

  .c-archive__news-wrapper {
    position: relative;
    order: 2;

    &:before {
      @include bp-down(medium) {
        content: '';
        position: absolute;
        left: $space * -2;
        right: $space * -2;
        top: 0;
        height: 100%;
        display: block;
        background: linear-gradient(180deg, #4840BB 30%, #8F53D7 100%);
        opacity: 0.1;
        z-index: 0;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: $space * 2;
      right: $space * -2;
      display: block;
      width: 190px;
      height: 210px;
      background: url('../assets/images/wealthfront-logo.svg') no-repeat left center;
      background-size: auto 100%;
      opacity: 0.05;
      z-index: 1;

      @include bp-between(medium, large) {
        right: auto;
        left: $space * 2;
        height: 145px;
      }

      @include bp(large) {
        right: $space * 2;
        width: 75%;
        background-size: contain;
        background-position: right center;
      }
    }

    @include bp(large) {
      order: 1;
    }
  }

  .c-archive__essentials-title {
    margin-bottom: $space;
  }

  .c-archive__top-grid {
    max-width: 100%;
  }

  .c-archive__featured--mark {
    display: block;

    @include bp(small) {
      display: none;
    }
  }
}

.c-archive__title {
  position: relative;
  margin-bottom: 0;
  font-family: $font-sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  z-index: 1;
}

.c-archive__subtitle {
  position: absolute;
  left: $space * 2;
  top: -$space;
  font-family: $font-serif;
  font-size: 144px;
  font-weight: 900;
  line-height: 180px;
  letter-spacing: -0.02em;
  color: $color-slate-500;
  opacity: 0.04;
  white-space: nowrap;
  z-index: 0;

  @include bp-down(medium) {
    top: $space * -2;
  }

  @include bp(xlarge) {
    margin-left: $space * -8;
  }
}

.c-archive__desc {
  max-width: 360px;
}

.c-archive {
  .c-archive__header-wrapper {
    @include bp-down(large) {
      display: none;
    }

    @include bp-between(large, xlarge) {
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: $space * 3;
    }
  }
}

.c-archive__header-wrapper {
  position: relative;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: $space;
  padding-top: 90px;
  padding-left: $space * 2;
  padding-right: $space * 2;

  @include bp(medium) {
    padding-top: 111px;
  }

  @include bp(large) {
    padding-top: 95px;
  }

  @include bp(xlarge) {
    padding-top: 86px;
  }
}

.c-archive__header {
  padding-left: $space * 4;

  @include bp(large) {
    padding-left: $space * 8;
  }
}

.c-archive__featured-wrapper {
  order: 0;
}

.c-archive__featured {
  position: relative;
  display: block;
  text-decoration: none;

  @include bp-between(600px, medium) {
    padding-bottom: $space * 6;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: 20%;
      height: 100%;
      background-color: white;
      z-index: 1;
    }
  }

  @include bp(medium) {
    overflow: visible;
  }

  @include bp(large) {
    margin-top: $space;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }
}

.c-archive__featured-img {
  display: block;
  width: calc(100% + #{$space * 6});
  max-width: calc(100% + #{$space * 6});
  margin-left: $space * -3;

  @include bp-between(600px, medium) {
    width: calc(100% + #{$space * 3});
    max-width: calc(100% + #{$space * 3});
    margin-left: -20%;
  }

  @include bp-between(medium, xlarge) {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
  }

  @include bp(xlarge) {
    width: 100%;
    max-width: calc(100% - #{$space * 3});
    margin-left: 0;
  }
}

.c-archive__featured-text {
  margin-top: $space * 2.5;

  @include bp-between(600px, medium) {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 78.5%;
    margin-left: auto;
    margin-top: 0;
    padding: $space * 3;
    background-color: white;
    z-index: 2;
  }

  @include bp-between(medium, large) {
    position: relative;
    margin-top: $space * -5;
    padding-top: $space * 3;
    background-color: white;
  }

  @include bp(large) {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 65%;
    margin-left: auto;
    margin-top: 0;
    padding: $space * 3;
    background-color: white;
    transform: translateY(#{$space * 4.5}); // includes padding and baseline adjustment
  }

  @include bp(xlarge) {
    width: 78.5%;
  }
}

.c-archive__featured-bg {
  display: none;
}

.c-archive__featured-post {
  position: relative;
}

.c-archive--subcategory {
  .c-archive__featured-bg {
    position: absolute;
    left: 0;
    right: 0;
    top: -500px;
    bottom: 40%;
    display: block;
    z-index: -1;

    @include bp(medium) {
      bottom: $space * 9;
    }

    @include bp(xlarge) {
      bottom: 50%;
    }
  }

  .c-archive__title {
    color: white;
    text-transform: none;
    font-weight: 600;
  }

  .c-archive__desc {
    color: white;
  }

  &.c-archive__no-featured {

    .c-archive__title,
    .c-archive__desc {
      color: $color-text;
    }
  }
}

.c-archive__featured-cat {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 40%;
    right: 100%;
    display: block;
    width: $space * 6;
    height: 1px;
    margin-right: $space * 1.5;
    background-color: $color-purple;

    @include bp-down(small) {
      display: none;
    }
  }
}

.c-archive__featured-title {
  margin: 0;
  color: $color-text;
  line-height: 1.2;
  font-size: 36px;
  font-weight: 900;

  span {
    position: relative;
    z-index: 1;
  }

  i {
    position: relative;
    display: inline-block;
    margin-top: $space * -0.7;
    padding: $space * 1.8 $space * 1.4;
    color: rgba($color-text, 0.6);
    font-size: 12px;
    background-color: $color-purple;
    z-index: 0;

    @include bp(medium) {
      margin-left: $space * -0.6;
    }
  }
}

.c-archive__news-top {
  @include bp-between(medium, large) {
    width: 185px;
    flex: 0 0 185px;
    padding-right: $space * 4;
  }

  .c-archive__news-title {
    padding-top: $space;
  }
}

.c-archive__news-cat.u-link {
  margin-top: $space * 2.5;

  @include bp-down(medium) {
    color: $color-purple;
  }
}

.c-archive__news-title {
  position: relative;
  font-family: $font-serif;
  font-weight: 900;

  @include bp-down(medium) {
    font-size: 36px;
  }

  @include bp-between(medium, large) {
    margin-bottom: $space * 5;
  }

  &:after {
    content: '';
    display: block;
    width: $space * 3;
    height: 3px;
    margin-top: $space / 2;
    background-color: $color-purple;

    @include bp-between(medium, large) {
      display: none;
    }
  }
}

.c-archive__news-list.owl-carousel {
  margin: 0;

  @include bp-down(medium) {
    width: 100%;
  }

  @include bp-between(medium, large) {
    width: 85%;
  }

  @media (min-width: 900px) and (max-width: 1024px) {
    width: 95%;
  }

  @include bp(medium) {
    display: block;
  }

  .owl-stage-outer {
    @include bp-down(medium) {
      overflow: visible;
    }
  }
}

.c-archive__news-list__item {
  max-width: 240px;
  margin: 0;
  padding: $space * 2.5 0;

  @include bp-down(medium) {
    padding-right: $space * 3;
  }

  @include bp-between(medium, large) {
    padding-right: $space * 2.5;
    padding-top: 0;
  }

  @include bp(medium) {
    max-width: 305px;
    border-bottom: 1px dashed $color-slate-200;
  }

  &:last-child {
    border-bottom: 0;
  }
}

.c-archive__news-list__item-cat {
  margin-bottom: $space * 0.5;
  color: $color-slate-200;
}

.c-archive__news-list__item-link {
  display: block;
  text-decoration: none;
  color: $color-text;
  font-size: 17px;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: -0.01em;
}

.c-archive__love-title,
.c-archive__love-title.u-small-text {
  display: flex;
  align-items: center;
  color: $color-text;
  font-weight: bold;
  letter-spacing: normal;
  font-size: 21px;

  span:after {
    @include bp-down(medium) {
      content: '';
      display: inline-block;
      width: 73px;
      height: 1px;
      margin-left: 5px;
      background-color: rgba($color-black, 0.4);
      vertical-align: middle;
    }
  }

  .owl-nav,
  span {
    display: block;
    vertical-align: middle;
  }

  .owl-nav {
    position: static;
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-left: auto;

    @include bp(medium) {
      margin-left: $space;
    }
  }

  .owl-prev,
  .owl-next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-left: 6px;
    border: 1px solid $color-slate-200;
    border-radius: 50%;
    transition: $global-transition;
  }

  i {
    font-size: 10px;
  }

  .owl-prev i {
    margin-right: 1px;
  }

  .owl-next i {
    margin-left: 1px;
  }
}

.c-archive__love {
  order: 1;
  margin-top: $space * 1.5;
  padding-left: $space;
  padding-right: $space;

  @include bp(medium) {
    padding-left: 0;
    padding-right: 0;
  }

  @include bp(large) {
    order: 2;
  }

  .owl-nav {
    margin-top: $space * -3;
  }
}

.c-archive .c-archive__love {
  @include bp(xlarge) {
    margin-top: $space * 4.5;
  }
}

.c-archive__love-list.owl-carousel {
  width: 100%;

  @include bp(medium) {
    width: auto;
    margin-right: $space * -2;
    margin-left: 0;
  }

  @include bp-between(large, xlarge) {
    margin-left: $space * -2.5;
  }

  @media (min-width: 1320px) {
    margin-right: $space * -4;
  }

  .owl-stage-outer {
    overflow: visible;
  }

  .owl-nav {
    @include bp(medium) {
      right: $space * 2;
    }

    @media (min-width: 1320px) {
      right: $space * 4;
    }
  }
}

.c-archive__love-link {
  display: block;
  max-width: 285px;
  font-size: 19px;
  text-decoration: none;

  @include bp(medium) {
    margin: 0 auto;
  }

  @include bp(xlarge) {
    max-width: 315px;
  }
}

.c-archive__love-link.mobile {
  display: flex;
  max-width: 100%;

  @include bp-between(medium, large) {
    flex-direction: column;
  }

  .c-archive__love-link-img {
    margin-right: 0;

    @include bp-between(large, xlarge) {
      display: none;
    }

    @include bp(xlarge) {
      width: calc(50% - 10px);
      flex: 0 0 calc(50% - 10px);
      margin-left: 0;
    }
  }

  .u-small-text {
    color: $color-purple;
  }

  .c-archive__love-list__item-title {
    font-size: 19px;
  }
}

.c-archive__love-list__item {
  position: relative;
  margin-top: $space * 2;
  padding: $space * 3 0;

  @include bp(medium) {
    padding: $space * 4 0;
  }
}

.owl-item .c-archive__love-list__item {
  margin-top: 0;
}

.c-archive__love-list__item-img {
  display: block;
  max-width: 270px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: $space * 2.5;

  @include bp(small) {
    max-width: 100%;
  }

  @include bp-down(large) {
    max-width: 225px;
    margin-left: 0;
    margin-right: 0;
  }

  @include bp-down(medium) {
    max-width: 120px;
  }
}

.owl-carousel .owl-item img.c-archive__love-list__item-img {
  width: 100%;
  margin-left: 0;
  margin-right: 0;

  @include bp(large) {
    margin-left: 0;
    margin-right: auto;
  }
}

.c-archive__love-list__item-title {
  margin-bottom: 0.3em;
  font-weight: 600;
  color: $color-text;
  line-height: 1.3;
  letter-spacing: -0.02em;
  text-transform: capitalize;
}

.c-archive__love-list__item-preview {
  color: $color-slate-300;
}

.c-archive__category-wrapper {
  position: relative;
  margin-top: $space * 6;
  margin-bottom: $space * 3;
  padding-top: $space * 6;

  @include bp(large) {
    margin-top: $space * 10;
  }
}

.c-archive__category-bg {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 50%;
  height: 100%;
  background-color: white;
  background-position: right top;
  background-size: 100% auto;
  background-repeat: no-repeat;
  opacity: 0.1;

  @include bp-down(large) {
    display: none;
  }
}

.c-archive__category {
  position: relative;
  padding-top: $space;
  padding-bottom: $space * 6;
  z-index: 2;

  @include bp(medium) {
    padding-right: $space * 4;
    padding-bottom: $space * 4;
  }

  @include bp(large) {
    padding-top: $space * 6;
    padding-bottom: 0;
    padding-right: $space * 10;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 200%;
    height: 100%;
    border: 1px solid #F3F3F3;
    z-index: -1;

    @include bp(large) {
      background-color: white;
      border: none;
      box-shadow: -80px -80px 100px rgba(0, 0, 0, 0.04);
    }
  }
}

.c-archive__category-title-decor,
.c-archive__category-title {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  font-weight: 900;
  line-height: 1;
  text-transform: uppercase;
  transform: translateY(-50%);

  @include bp(large) {
    font-size: 60px;
  }
}

.c-archive__category-title {
  margin-left: $space * 5;
  z-index: 3;
}

.c-archive__category-title-decor {
  @extend h2;
  font-family: $font-serif;
  font-weight: 900;
  line-height: 0.75;
  text-transform: none;
  transform: translateY(-87%);
  z-index: 2;

  @include bp-down(large) {
    font-size: 90px;
  }

  @include bp(large) {
    right: 0;
    left: auto;
    font-size: 120px;
    transform: rotate(90deg) translate3d(100%, -100%, 0);
    transform-origin: 100% 0;
    z-index: -1;

    .mac & {
      right: $space * 1.5;
    }
  }

  span {
    position: relative;
    opacity: 0.04;
    white-space: nowrap;
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    right: $space * -4;
    bottom: $space * -2;
    display: block;
    width: 500%;
    height: 100%;
    background-color: $color-bg;
    z-index: 0;

    @include bp(large) {
      display: none;
    }
  }
}

.c-archive__category-item {
  margin-top: $space * 3.5;
}

.c-archive__category-link.c-post__related-post {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
  margin: 0 auto;
  text-decoration: none;

  @include bp-between(small, medium) {
    padding-left: 145px;
  }

  @include bp(medium) {
    display: block;
    max-width: 255px;
  }

  .rp4wp_component_image {
    overflow: hidden;

    @include bp-between(medium, large) {
      height: 120px;
    }

    img {
      @include bp-down(large) {
        position: relative;
        left: 50%;
        max-width: 500%;
        height: 100%;
        transform: translateX(-50%);
      }
    }
  }
}

.c-archive__category-item-cat {
  margin-top: $space;
  margin-bottom: $space * 0.25;
  color: $color-slate-200;

  @include bp(large) {
    margin-top: $space * 1.5;
  }
}

.c-archive__category-more.c-btn {
  @include bp-down(medium) {
    position: absolute;
    left: 50%;
    bottom: -21px;
    background-color: $color-slate-500;
    border-color: $color-slate-500;
    color: white;
    transform: translateX(-50%);
  }

  @include bp(medium) {
    margin-top: $space * 3;
  }

  @include bp(large) {
    margin-left: $space * 1.5;
  }
}

.c-archive__category--content {
  margin-left: $space * -1.5;
  margin-right: $space * -1.5;

  @include bp-between(medium, large) {
    width: 100%;
  }
}

.c-archive__category--right {
  @include bp-down(medium) {
    margin-left: $space * -1.5;
  }

  @include bp-down(large) {
    justify-content: center;
  }

  @include bp(large) {
    .c-archive__category {
      padding-right: 0;
      padding-left: 100px;
    }

    .c-archive__category:after {
      right: auto;
      left: 0;
    }

    .c-archive__category-bg {
      right: auto;
      left: $space;
    }

    .c-archive__category--content {
      margin-right: $space * -2;
    }

    .c-archive__category-title {
      left: auto;
      right: 0;
      margin-left: 0;
      margin-right: $space * 5;
    }

    .c-archive__category-title-decor {
      right: auto;
      left: 0;
      transform: rotate(-90deg) translate3d(-100%, -100%, 0);
      transform-origin: 0 0;

      .mac & {
        left: $space * 1.5;
      }
    }
  }
}

.c-archive__featured--mark {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  margin: 0;
  margin-right: $space * -2.5;
  padding: $space 0;
  background-color: white;
  transform: rotate(90deg) translateX(100%);
  transform-origin: 100% 0;

  @include bp(small) {
    display: none;
  }
}

.c-archive__category-list {
  padding-top: $space * 4;

  @include bp(medium) {
    margin: 0 $space * -1;
  }
}

.c-archive__essentials {
  margin-top: $space * 4;
}

.c-archive__essentials-title {
  @include bp(medium) {
    position: absolute;
    left: 0;
    top: $space * 4.2;
    transform: rotate(-90deg) translate3d(-100%, -100%, 0);
    transform-origin: 0 0;
  }

  &:after {
    display: none;
  }
}

.c-archive__essentials-title span:after {
  content: '';
  display: inline-block;
  width: 73px;
  height: 1px;
  margin-left: 5px;
  background-color: rgba($color-black, 0.4);
  vertical-align: middle;

  @include bp(large) {
    margin-left: $space * 2;
  }
}

.c-archive__essentials-wrapper {
  overflow: hidden;
}

@include bp-between(medium, large) {
  .c-archive .rp4wp_component_title {
    font-size: 17px;
  }
}

.c-archive__news-list {
  @include bp-down(large) {
    .owl-nav {
      margin-top: $space * -1.5;
    }
  }
}

.c-archive__essential-content {
  @include bp-down(large) {
    padding-left: $space * 5;
  }

  @include bp-down(medium) {
    padding-left: $space * 1.5;
  }

  @include bp-between(large, xlarge) {
    margin-bottom: 1.25em;
    padding-bottom: 0.9em;
    border-bottom: 1px dashed $color-slate-200;
  }

  @include bp-between(medium, xlarge) {
    padding-left: 0;
  }

  @include bp(xlarge) {
    padding-left: $space * 2;
  }
}

.c-archive__essentials-row .o-grid__col {
  @include bp-down(medium) {
    margin-left: -10px;
    padding: 0;
  }

  @include bp-between(medium, large) {
    flex: 1;
    width: 33.3333333%;
  }
}

.c-archive__top-grid {
  @include bp-down(medium) {
    max-width: 100%;
  }
}

.c-archive__home-title {
  position: static;
  padding-top: 5px;
  transform: none;
}

.c-archive__news-title-link {
  text-decoration: none;

  &,
  &:hover {
    color: $color-text;
  }
}

.c-archive__love-list .c-archive__love-list__item-img {
  width: 100%;
  max-width: 100%;
}
