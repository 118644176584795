/* ==========================================================================
   #PAGE
   ========================================================================== */
html {
  background: $color-bg;
  color: $color-text;
  font-family: $font-sans-serif;
  font-size: 19px;
  line-height: 1.5;
  min-height: 100%;
  padding: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  @include bp(medium) {
    font-size: 21px;
  }
}
