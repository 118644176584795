.u-link {
  @extend .u-small-text;
  display: inline-block;
  line-height: 14px;
  font-weight: bold;
  color: $color-medium-grey;
  text-decoration: none;

  @include bp(medium) {
    font-size: $font-size-md;
    line-height: 18px;
    color: $color-purple;
  }

  &:hover {
    color: $color-purple;
    text-decoration: underline;
  }
}

.u-link-purple {
  @extend .u-small-text;
  display: inline-block;
  line-height: 14px;
  font-weight: bold;
  color: $color-purple;
  text-decoration: none;

  @include bp(medium) {
    font-size: $font-size-md;
    line-height: 18px;
  }

  &:hover {
    text-decoration: underline;
  }
}

.u-link--small {
  font-size: $font-size-sm;
  line-height: 1.5;
}

.u-link--alike {
  @include bp-down(medium) {
    opacity: 0.6;
  }

  &:hover {
    text-decoration: none;
    color: $color-medium-grey;
  }
}
