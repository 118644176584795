$color-purple-blank: #F6F5FC;

.c-essential-guides {
  margin-top: $space * 10;
  background-color: $color-purple-blank;

  .owl-nav.disabled {
    display: flex;
  }

  .owl-nav {
    margin-top: $space * -3.5;

    @include bp-down(large) {
      right: $space * 2;
    }

    @include bp(xlarge) {
      margin-top: $space * -0.7;
    }
  }
}

.c-essential-guides__container {
  @include bp(medium) {
    padding-left: $space * 12;
  }

  @include bp(xlarge) {
    padding-left: 0;
  }
}

.c-essential-guides__title {
  @extend .c-archive__category-title-decor;
  position: static;
  font-family: $font-sans-serif;
  text-transform: uppercase;
  color: $color-purple-blank;
  font-size: 44px;
  line-height: 0.5;
  white-space: nowrap;
  transform: translateY(-100%);

  .ios & {
    line-height: 0.6;
  }
  
  @include bp-between(medium, large) {
    font-size: 60px;
  }

  @include bp(large) {
    font-size: 90px;
  }

  &:after {
    display: none;
  }
}

.c-essential-guides__list.owl-carousel .owl-stage-outer {
  overflow: visible;

  img {
    width: initial;
  }
}

.c-essential-guides__list {
  margin-top: $space * -5.5;

  @include bp(medium) {
    margin-top: $space * -6.3;
  }

  @include bp(large) {
    margin-top: $space * -9.2;
  }
}

.c-essential-guides__list-item {
  position: relative;
  max-width: 100vw;
  height: 380px;
  padding-right: $space * 5;

  @include bp-down(medium) {
    padding-left: $space * 2;
  }

  &.item-1 {
    padding-left: $space * 2.5;
    padding-right: $space * 5;

    @include bp(medium) {
      width: 795px;
      padding-left: $space * 16;
      padding-right: $space * 9;
    }

    @include bp(large) {
      padding-left: $space * 20;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: $space * 3.4;
      display: block;
      width: $space * 1.5;
      height: 2px;
      background-color: $color-purple;
      
      @include bp(medium) {
        width: $space * 4;
      }

      @include bp(large) {
        top: $space * 4.8;
        width: $space * 8;
      }
    }
  }

  &.item-2 {
    @include bp(medium) {
      padding-right: $space * 10;
    }
  }

  &.item-3 {
    @include bp(medium) {
      padding-right: $space * 13;
    }
  }
}

.c-essential-guides__list-item-text {
  position: relative;
  z-index: 1;
}

.c-essential-guides__list-item-title {
  margin-bottom: $space * 1.5;
  font-family: $font-serif;
  line-height: 0.8;
  font-size: 36px;
  font-weight: 900;
  line-height: 40px;

  @include bp(large) {
    margin-bottom: $space * 2;
    font-size: 54px;
    line-height: 54px;
  }

  .mac & {
    @include bp(large) {
      margin-top: $space * 0.7;
    }    
  }

  span {
    display: block;
    color: #8F53D7;
  }
}

a.c-essential-guides__list-item-link {
  padding-bottom: 0;
  border-bottom: 2px solid currentColor;
  text-decoration: none;
  color: $color-purple;

  &:hover {
    text-decoration: none;
    border-bottom: none;
  }
}

.c-essential-guides__list-item-preview {
  max-width: 385px;
  font-size: 19px;
  line-height: 23px;
  color: rgba($color-purple, 0.6);
}

.c-essential-guides__list-item-icon {
  position: absolute;
  left: $space * 2;
  bottom: 0;
  z-index: 0;

  @include bp(medium) {
    left: auto;
    right: 0;
  }
}