.wp-block-image {
  margin-top: $space * 3;
  margin-bottom: $space * 3;

  img {
    height: auto;
  }

  figcaption {
    margin-top: $space;
    margin-bottom: 0;
  }
}