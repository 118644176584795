/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  margin-top: $space * 4;
  padding: $space * 6 0;
  background-color: $color-text;
  color: rgba(white, 0.3);
  font-size: $font-size-md;
  line-height: 1.3;
  font-weight: 300;

  p {
    color: inherit;
  }
}

.c-footer__nav {
  @include bp(medium) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: -$space;
    margin-right: -$space;
    margin-bottom: $space * 2;
  }

  @include bp(xlarge) {
    flex-wrap: nowrap;
  }
}

.c-footer__nav--secondary {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: $space;
  padding: $space * 2.5 0;
  align-items: center;

  @include bp(medium) {
    flex-wrap: nowrap;
    margin-top: 0;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    height: 1px;
    background-color: rgba($color-slate-100, 0.1);

    @include bp(medium) {
      left: $space;
      right: $space;
    }
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  .c-footer__item {
    white-space: nowrap;
    margin-right: $space * 2;

    @include bp(medium) {
      margin-right: 0;
    }
  }
}

.c-footer__item {
  margin-bottom: $space * 1.5;

  @include bp(medium) {
    margin: 0;
  }

  @include bp(xlarge) {
    margin-right: $space * 4;
  }

  &:last-child {
    margin-right: 0;
  }

  &.menu-item-has-children {
    @include bp(medium) {
      display: flex;
      align-items: center;
    }

    >.c-footer__link {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    >.c-footer__link:after {
      content: '+';
      display: inline-block;
      margin-left: $space;
    }

    &.active {
      width: 100%;

      >.c-footer__link {
        @include bp(medium) {
          width: auto;
        }

        @include bp(xlarge) {
          margin-right: $space * 2;
        }
      }

      >.c-footer__link:after {
        content: '-';
      }
    }
  }
}

.c-footer__nav--main {
  justify-content: flex-start;
  flex-wrap: wrap;

  .c-footer__item {
    position: relative;
    max-width: 100%;

    @include bp-between(medium, large) {
      margin-top: $space * 2;
    }
  }
}

.c-footer__children {
  display: none;
  flex-wrap: wrap;
  margin: 0;
  margin-top: $space;

  @include bp(medium) {
    margin-top: 0;
  }

  .active & {
    display: block;

    @include bp(medium) {
      display: flex;
    }
  }
}

.c-footer__item--child {
  margin-bottom: 0;

  @include bp(large) {
    margin-right: $space * 2;
  }

  @include bp(xlarge) {
    margin-right: $space * 4;
  }
}

.c-footer__link {
  display: block;
  width: 100%;
  padding-top: $space / 2;
  padding-bottom: $space / 2;
  font-size: 17px;
  color: rgba(white, 0.6);
  font-weight: 400;
  text-decoration: none;

  @include bp(medium) {
    padding: $space / 2 $space;
  }

  &:active,
  &:focus {
    color: white;
  }
}

.c-footer__link--heading {
  font-size: 19px;
  font-weight: 600;
  color: $color-slate-100;
}

.c-footer__home {
  display: inline-block;
  margin-bottom: $space * 3;

  @include bp(medium) {
    margin-bottom: 0;
  }

  svg path {
    fill: white;
  }
}

.c-footer__item--socials {
  display: flex;
  align-items: center;
  width: 100%;

  @include bp(medium) {
    width: auto;
  }

  @include bp(xlarge) {
    margin-left: 70px;
  }
}

.c-footer__link--social {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 36px;
  width: 36px;
  height: 36px;
  margin-right: $space;
  border: 1px solid rgba(white, 0.3);
  border-radius: 50%;
  text-decoration: none;
  color: white;

  .title {
    position: absolute;
    left: -9999px;
    display: block;
    width: 0;
    height: 0;
    visibility: hidden;
  }
}
