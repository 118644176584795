/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $header;
  background-color: white;
  z-index: 10;

  @include bp(xlarge) {
    height: $header + 12px;
    padding: $space * 0.5 0;
  }

  .single-post & {
    @include bp(xlarge) {
      height: $header + 8px;
    }
  }

  .logged-in.admin-bar & {
    top: 32px;
  }
}

.c-header__inner {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1240px;
  margin: 0 auto;
  padding-left: $space * 0.5;
  padding-right: $space * 0.5;

  @include bp(xlarge) {
    max-width: 1360px;
    padding-left: $space * 1.7;
    padding-right: $space * 2.5;
  }

  @media (min-width: 1360px) {
    padding-right: $space * 7;
  }
}

.c-header__home {
  position: relative;
  display: block;
  padding: $space * 2 $space;

  svg {
    display: block;
  }
}

.c-header__blog.c-main-nav__link {
  position: relative;
  padding: $space;
  font-size: 19px;

  .category &,
  .archive & {
    opacity: 1;
  }

  @include bp(medium) {
    display: none;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    display: block;
    height: $space * 2;
    width: 1px;
    margin-top: -$space;
    background-color: $color-slate-100;
  }
}
