/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

$trans: opacity 0.3s ease-in-out;

.c-main-nav__wrapper--post {
  position: absolute;
  left: -9999px;
  top: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;

  @include bp(medium) {
    position: static;
    width: auto;
    height: auto;
    max-width: 100%;
    opacity: 1;
    transform: none;
  }
}

.c-main-nav__wrapper {
  display: block;
  background-color: white;
  transition: $trans;
  overflow: auto;
  z-index: 3;

  @include bp(medium) {
    display: flex;
    flex-direction: column;
    padding-left: $space;
  }

  .nav-open & {
    left: 0;

    @include bp(medium) {
      left: auto;
      right: 0;
    }
  }

  .nav-open.animate-in & {
    opacity: 1;

    @include bp(medium) {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .nav-open.animate-out & {
    opacity: 0;

    @include bp(medium) {
      opacity: 1;
      transform: translateX(100%);
    }
  }
}

.c-main-nav__toggle {
  position: relative;
  padding: $space;
  font-size: 19px;
  z-index: 3;

  @include bp(medium) {
    display: none !important;
  }

  &:hover {
    @include bp(xmedium) {
      .icon-menu {
        color: $color-slate-200;
      }

      .icon-close {
        color: $color-text;
      }
    }
  }

  .icon-menu {
    display: block;
    width: 19px;
    transition: $global-transition;
  }
}

.c-main-nav__search {
  margin-left: auto;
  padding: $space;
  font-size: 15px;

  @include bp(medium) {
    order: 99;
  }
}

.c-main-nav {
  @extend .c-container;
  position: relative;
  margin: 0 auto;
  list-style: none;
  overflow: auto;

  @include bp(medium) {
    padding-left: $space;
    overflow: hidden;
  }

  &:before {
    content: '';
    position: absolute;
    left: 6px;
    top: 50%;
    display: block;
    height: $space * 2;
    width: 1px;
    margin-top: -$space;
    background-color: $color-slate-100;
  }

  @include bp(medium) {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1300px;
    overflow: visible;
    margin: 0;
  }
}

.c-main-nav__item {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid $color-border;

  @include bp(medium) {
    border-bottom: none;
  }

  &.external {
    @include bp(medium) {
      display: flex;
      align-items: center;
      margin: 0;
      margin-left: auto;
      order: 10;
    }

    @include bp(large) {
      padding-right: $space * 1.3;
    }

    @include bp(xlarge) {
      padding-top: $space * 1.1;
      padding-bottom: $space * 1.1;
    }
  }

  &.menu-item-has-children:hover {
    @include bp(medium) {
      .c-main-nav__subnav {
        height: auto;
      }
    }
  }
}

.c-main-nav__link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $space * 3;
  padding-right: $space * 2.5;
  font-weight: 800;
  font-size: 19px;
  line-height: 1.25;
  text-transform: uppercase;
  text-decoration: none;
  color: $color-text;
  transform: translateX(0);
  opacity: 1;
  transition: $global-transition;

  @include bp(medium) {
    padding: $space $space * 0.8;
    font-size: 14px;
  }

  @include bp-between(790px, large) {
    font-size: 1.9vw;
  }

  @include bp(large) {
    font-size: 19px;
  }

  @include bp(xlarge) {
    padding: $space * 1.5 $space * 1.2;
  }

  &:hover,
  &.active {
    @include bp(xmedium) {
      color: $color-text;
    }
  }

  .category &,
  .archive & {
    opacity: 0.3;
  }

  .c-main-nav__item.external & {
    padding-top: $space * 1.4;
    padding-bottom: $space * 1.4;
    font-size: $font-size-sm;
    font-weight: bold;
    letter-spacing: 0.05em;
    line-height: 26.25px;
    color: $color-text;
    opacity: 1;

    @include bp(medium) {
      padding-top: $space * 0.5;
      padding-bottom: $space * 0.5;
    }

    @include bp(large) {
      padding-top: $space * 0.8;
      padding-bottom: $space * 0.8;
    }

    @include bp-between(medium, large) {
      padding-right: 0;
    }

    @include bp(xlarge) {
      margin-left: auto;
      padding-top: $space * 0.3;
      padding-bottom: $space * 0.2;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .icon-chevron-right {
    font-size: 12px;

    @include bp(xmedium) {
      display: none;
    }

    .c-main-nav__item.external & {
      display: none;
    }
  }
}

.current-menu-parent .c-main-nav__link,
.current-menu-item .c-main-nav__link {
  opacity: 1;
}

.c-main-nav__subitem {
  margin: 0;
  margin-bottom: $space;
  list-style: none;

  @include bp(medium) {
    margin-bottom: 0;
  }
}

.c-main-nav__sublink {
  background: $color-white;
  border-bottom: 1px solid $color-border;
  display: block;
  padding: $space;
  text-decoration: none;
}

.current-menu-item .c-main-nav__sublink {
  border-bottom-color: $color-black;
}

.c-main-nav__open-close {
  position: relative;
  text-align: center;
  font-weight: 500;

  @include bp(medium) {
    display: none;
  }

  .minus {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    opacity: 0;
    transform: translateY(-50%);
  }

  .plus,
  .minus {
    transition: $global-transition;
  }

  .c-main-nav__link.active & {
    .minus {
      opacity: 1;
    }

    .plus {
      opacity: 0;
    }
  }
}

.c-main-nav__subnav {
  height: 0;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;

  @include bp(medium) {
    position: absolute;
    left: 0;
    top: 100%;
    background-color: white;
  }
}

.c-main-nav__link.active+.c-main-nav__subnav {
  height: auto;
  transform: translateX(0);
}

.c-main-nav__subnav-links-wrapper {
  @extend .c-container;
  position: relative;
  height: 100%;
  overflow: hidden;
  z-index: 2;
}

.c-main-nav__subnav-links {
  position: relative;
  margin: 0;
  z-index: 1;
}

.c-main-nav__subnav-desc,
.c-main-nav__subnav-link {
  display: block;
  padding-left: $space * 5.5;
  padding-right: $space;

  @include bp(medium) {
    padding-left: $space * 2;
    padding-right: $space * 2;
  }
}

.c-main-nav__subnav-link {
  display: block;
  text-decoration: none;
  font-weight: 600;
  font-size: 21px;
  line-height: 2.5;
  letter-spacing: -0.01em;
  color: $color-text;
}

.c-main-nav__subnav-desc {
  max-width: 300px;
  font-size: 19px;
  letter-spacing: -0.01em;
  color: $color-slate-200;
}

.c-main-nav__social {
  position: relative;
  padding: $space * 3;
  transform: translateX(0);
  transition: $global-transition;
  z-index: 2;

  @include bp(xmedium) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: $space * 3 $space * 3 $space * 1.5;
  }
}

.c-main-nav__social-title {
  @extend .u-small-text;
  display: block;
  margin-bottom: $space * 2;
  line-height: 14px;
  color: $color-slate-200;
}

.c-main-nav__socials {
  display: flex;
  align-items: center;
  margin: 0;
  list-style: none;
}

.c-main-nav__socials-item {
  margin: 0;
  margin-right: $space;
}

.c-main-nav__social-link {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  margin: 0;
  border-radius: 50%;
  border: 1px solid $color-text;
  text-decoration: none;
  font-size: $font-size-sm;
  color: $color-text;

  &:hover {
    @include bp(xmedium) {
      color: $color-slate-200;
    }
  }

  i {
    display: block;
    align-self: center;
  }
}

.c-main-nav__footer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  @include bp(xmedium) {
    flex: none;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: auto;
  }

  &:before {
    @extend .c-search__items--decor;
    display: none;

    @include bp(xmedium) {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
    }
  }

  @include bp(medium) {
    display: none;
  }
}

.c-main-nav__wrapper {
  @include bp(medium) {
    flex: 1;
    padding-left: 0;
    overflow: visible;
  }

  .c-main-nav__footer:before {
    height: 60px;
    opacity: 0.05;
  }
}

.c-main-nav__bottom {
  margin-top: auto;
  padding: 0 $space * 3 $space * 5;
  transition: $global-transition;

  @include bp(xmedium) {
    display: flex;
    align-items: center;
    margin-top: 0;
    padding: $space * 1.5 $space * 3;
  }
}

[data-android][data-ios] {
  .desktop & {
    display: none;
  }
}

.c-main-nav__app {
  display: none;

  .desktop & {
    display: block;
    width: 107px;
    height: 36px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    text-decoration: none;
    text-indent: 100%;
    overflow: hidden;
    white-space: nowrap;
  }

  &.android {
    background-image: url('../assets/images/app-android.jpg');
  }

  &.ios {
    margin-right: $space / 2;
    background-image: url('../assets/images/app-ios.jpg');
  }
}

.c-main-nav__bottom-download {
  display: none;

  .desktop & {
    display: block;
  }
}

.c-main-nav__subnav-featured {
  position: relative;
  max-width: 100%;
  padding: $space * 2.5 $space * 3;
  font-size: 19px;
  color: $color-slate-200;
  z-index: 3;

  @include bp(medium) {
    display: none;
  }

  img,
  picture {
    margin-bottom: $space * 1.5;
  }

  strong {
    color: $color-text;
  }

  a {
    text-decoration: none;
  }
}

.c-main-nav__featured-title {
  margin: 0;
  padding-bottom: $space * 2;
  font-size: 24px;
  font-family: $font-serif;
  font-weight: 900;
  line-height: 1.25;
  color: $color-text;
}

.c-main-nav__featured-cat {
  @extend .u-small-text;
  margin-bottom: $space;
  color: $color-purple;
}

.c-main-nav__featured-preview {
  font-size: 19px;
  line-height: 1.5;
  letter-spacing: -0.01em;
}

.c-main-nav__wrapper--backdrop {
  position: absolute;
  left: -9999px;
  top: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  background-color: rgba($color-slate-500, 0.6);
  transition: $trans;
  z-index: 2;

  .nav-open & {
    left: 0;
  }

  .nav-open.animate-in & {
    opacity: 1;
  }

  .nav-open.animate-out & {
    opacity: 0;
  }
}

.c-main-nav__close {
  position: absolute;
  top: 7.5px;
  right: 0;
  padding: $space * 1.5;
  font-size: 15px;
  z-index: 1;

  @include bp(medium) {
    display: none;
  }
}
