.c-author__title {
  margin-bottom: $space;
}

.c-author__desc {
  max-width: 580px;
  margin-bottom: $space * 6;
  padding: 0 $space * 2.5;

  @include bp(medium) {
    padding: 0;
  }
}
