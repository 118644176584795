/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

.c-btn {
  @extend .u-small-text;
  display: inline-block; /* [1] */
  vertical-align: middle; /* [2] */
  font-family: inherit; /* [3] */
  line-height: 14px;
  text-align: center; /* [4] */
  margin: 0; /* [4] */
  padding: $space * 1.2 $space * 3;
  cursor: pointer; /* [5] */
  border-radius: 0;
  border: 2px solid currentColor;
  text-decoration: none;
  transition: $global-transition;
}

/* Style variants
   ========================================================================== */

.c-btn--primary {
  border-color: $color-purple;

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none; /* [4] */
    color: $color-purple;
  }

  &:hover {
    background-color: $color-purple;
    color: white;
  }
}

.c-btn--full {
  padding: $space * 1.4 $space * 2.5;
  border-color: $color-purple;
  background-color: $color-purple;
  
  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none; /* [4] */
    color: white;
  }

  &:hover,
  &:focus {
    background-color: transparent;
    color: $color-purple;
  }
}

/* Size variants
   ========================================================================== */

.c-btn--small {
  padding: 0.25rem 0.5rem;
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
}
